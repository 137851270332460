const BusinessPartnerTypesEnum : any = {
    Admin: 1,
    NormalUser: 2,
    Customer: 3,
    Vendor: 4,
    SalesRepresentative: 5
  };

  export default BusinessPartnerTypesEnum;

