
import {MenuItem} from './MenuItem'
import {MenuInnerWithSub} from './MenuInnerWithSub'
import {MegaMenu} from './MegaMenu'

export function MenuInner() {
  //const intl = useIntl()
  return (
    <>
      <MenuItem title={'Sheeba Inventory System'} to='/' />
     
    </>
  )
}
